import { serverSideTranslations } from "next-i18next/serverSideTranslations";

// Components
import Page404 from "components/page404/Page404";

export default function Page404Web() {
    return <Page404 />;
}

export async function getStaticProps({ locale }: any) {
    return {
        props: {
            ...(await serverSideTranslations(locale))
        }
    };
}
