import React, { useContext } from "react";
import Head from "next/head";

// Components
import AppImg from "../../components/utils/AppImg";
import Button from "@vahak/core-ui/dist/components/Button";
import Flex from "@vahak/core-ui/dist/layout/Flex";

// Styles
import Styles from "./Page404.module.scss";
import Typography from "@vahak/core-ui/dist/components/Typography";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useFeatureByServiceType } from "../../hooks/useFeatureByServiceType";
import { AppContext } from "@vahak/core/dist/app-context";
import { CompanyServiceType } from "@vahak/core/dist/_services/auth.service";
import Router from "next/router";

const Page404 = () => {
    const { customerServiceType, serviceType, setServiceType, isCsDashboardUser } = useContext(AppContext);
    const _serviceType = customerServiceType || serviceType;
    const isMobileScreen = useMediaQuery({ queryType: "mobile" });
    const allowedFeature = useFeatureByServiceType();

    const showLoadFeatures = allowedFeature.showLoadFeatures || !_serviceType;
    const showLorryFeatures = allowedFeature.showLorryFeatures || !_serviceType;

    const updateServiceType = () => {
        setServiceType?.(CompanyServiceType.TRANSPORT_CONTRACTOR);
    };
    const redirectLoadLorryMkt = (target: "load" | "lorry") => {
        return () => {
            Router.replace(`/marketplace/${target}`);
            !_serviceType && updateServiceType();
        };
    };

    return (
        <React.Fragment>
            <Head>
                <title>Vahak - No Page Found</title>
                <meta name="robots" content="noindex, nofollow" />
            </Head>
            <Flex justifyContent="center" className={Styles.main}>
                <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    gap={20}
                    padding={20}
                    className={Styles.content}
                >
                    <AppImg
                        src={`/Error-404.svg`}
                        layout="fixed"
                        width={isMobileScreen ? 320 : 904}
                        height={isMobileScreen ? 87 : 247}
                        className={Styles.bannerImg}
                        unoptimized
                    />
                    <Typography weight="bold" size={isMobileScreen ? "l" : "xxl"} as="p">
                        OH! NO
                    </Typography>
                    <Typography weight="medium" size={isMobileScreen ? "md" : "xl"} as="p">
                        Page Not Found
                    </Typography>
                    <Typography color={COLORS.GREY_700} size={isMobileScreen ? "s" : "md"} as="p" align="center">
                        Start Working with VAHAK that can help you in providing a seamless business communication
                        environment for Road Transporters, Individual Lorry Owners and Consignors.
                    </Typography>
                    {!isCsDashboardUser && (
                        <Flex
                            justifyContent="center"
                            gap={32}
                            style={{ marginTop: 60 }}
                            {...(isMobileScreen && {
                                flexDirection: "column",
                                alignItems: "center"
                            })}
                        >
                            {showLoadFeatures && (
                                <Button width={275} onClick={redirectLoadLorryMkt("load")}>
                                    Find Load
                                </Button>
                            )}
                            {showLorryFeatures && (
                                <Button width={275} onClick={redirectLoadLorryMkt("lorry")}>
                                    Find Lorry
                                </Button>
                            )}
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </React.Fragment>
    );
};

export default Page404;
